<template>
    <div class="">

        <button class="button button--secondary button--new" @click="toggleForm">
            <inline-svg class="icon" :src="require('@/assets/images/ic_new.svg')" width="24" />
            <span>{{$t('post_title_create_4')}}</span>
        </button>

        <KappCategoryNav :categories="categories"/>


        <div class="list list--article">
            <KappNews v-for="article in filteredArticles" :available_categories="categories"
                      :article="article"
                      v-bind:key="'uid-' + article.uid"
                      :uid="article.uid"
                      class="article--bulletin"
                      :class="{ 'is-invisible' : article.fullView }"
                      layout="bulletin"
                      :like_settings="like_settings"
                      :teaserLength="60"
                      @click="openNews(article)" />
        </div>

        <Lightbox :is-visible="showForm" @close="showForm=false">
            <template v-slot:content>
                <KappPostsNew :doktype="4" />
            </template>
        </Lightbox>

        <Lightbox :is-visible="selectedArticle !== false" @close="selectedArticle = false" :seamless="true">
            <template v-slot:content>
                <KappNews v-if="selectedArticle"
                          :single="true"
                          :article="selectedArticle"
                          layout="bulletin"
                          :uid="selectedArticle.uid"
                          :available_categories="categories" />
            </template>
        </Lightbox>

        <ReloadButton @reload="fetchData" type="bulletin" />
    </div>
</template>

<script>

import KappNews from '@/components/Blocks/KappNews';
import debounce from 'lodash.debounce';
import api, { ApiDefaults } from '@/utils/api_call';
import { STORE_BULLETIN } from '@/utils/store/actions/general';
import Lightbox from '@/components/Plugins/Lightbox';
import KappPostsNew from './KappPostsNew';
import EventsBus from '@/utils/EventsBus';
import KappCategoryNav from '../../components/Subparts/KappCategoryNav';
import { mapGetters } from 'vuex';
import ReloadButton from '../KappMain/ReloadButton';


export default {
	name: "KappPostsList",
	components: {
		KappCategoryNav,
		KappPostsNew,
		KappNews,
		Lightbox,
		ReloadButton
	},
	data() {
		return {
			showForm: false,
			categories: [],
			showCategoryNav: false,
			categoryFilter: 0,
			animDirection: 'slide-left',
			pageTitle: 'Schwarzes Brett',
			like_settings: {pid: this.$route.meta.uid, target: (this.$route.name === 'ImpressionList' ? 'impression' : 'bulletin')},
			selectedArticle: false,
			newArticles: 0,
			backScroll: false
		};
	},
	methods: {
		openNews: function(article) {
			this.$router.push({path:'/beitrag/' + article.uid });
		},
		toggleForm: function () {
			this.showForm = true;
		},
		fetchData: debounce(function () {

			let apiParams = Object.assign({}, ApiDefaults, {method: 'get', params: {id: 8, 'tx_c3json_jsondataplugin[action]': 'bulletinBoard'}});
			api(apiParams)
				.then(( response ) => {
					if ( response.status === 200 ) {
						let articles = [];
						articles = response.data.news.content.map(( item ) => {

													// item.categories = item.categories;
													item.visible = true;

													return item;
												})
												.sort(( a, b ) => {
													return b.datetime - a.datetime;
												});
						this.categories = Object.values(response.data.news.categories);
						EventsBus.trigger('showCategories', this.categories);

						this.$store.commit(STORE_BULLETIN, articles);
						// this.$store.commit(STORE_BULLETIN_CATEGORIES, this.categories);
					}
				})
				.catch(( err ) => {
					console.warn(err.message);
				})
				.finally(() => this.hideArticleEffect = 'hideArticles');


		}, 125),

		switchCategory: function ( val ) {
			if ( val === '' ) {
				this.animDirection = 'news-right';
				val = 0;
			}
			else {
				val = val.toString().split('-')[ 1 ];

				if ( val < this.categoryFilter )
					this.animDirection = 'news-right';

				else
					this.animDirection = 'news-left';
			}

			this.$nextTick(() => this.categoryFilter = parseInt(val));
		},
		changeCategory: function ( evt ) {
			// ignore header component
			if ( evt.target.classList.contains('header') ) return;
		},
		resetLightbox: function () {
			this.selectedArticle = false;
		}
	},
	computed: {
		...mapGetters([ 'bulletinItems', 'bulletinItemsByCategory' ]),
		filteredArticles: function () {

			if ( !this.bulletinItems ) return [];

			if(this.categoryFilter > 0) return this.bulletinItemsByCategory( this.categoryFilter );

            return this.bulletinItems;
		}
	},
	created() {
		this.fetchData();
		EventsBus.on('lightbox:closed', this.resetLightbox);
		EventsBus.on('switchContentCategory', this.switchCategory);
	},
	updated() {
		if ( this.backScroll ) {
			let newsId = "news_" + this.backScroll;
			this.$nextTick(() => {
				let el = document.querySelector('.list.list--article #' + newsId);
				window.scrollTo( {top: el.offsetTop })
			});

			this.backScroll = false;
		}
	},
	beforeRouteEnter( to, from, next ) {
		if ( from.name === 'Beitrag' ) {
			let uid = from.params.id;
			next( vm => {
				vm.backScroll = uid;
			})
		}
		else{
			next();
		}
	}
};
</script>

<style scoped>

.button.button--new {
    margin-bottom: 15px;
    position: relative;
}

.button.button--new i[class*="icon-"] {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
}

.button-fixed {
    position: fixed;
    right: 0;
    bottom: 30px;
    background: #E4223C;
    color: #FFF;
    font-size: 20px;
    line-height: 1;
    padding: 14px 8px;
    padding-left: 20px;
    clip-path: polygon(0 50%, 20px 0, 100% 0, 100% 100%, 20px 100%, 0 50%);
}
</style>
